import axios from 'axios';
// config
import { HOST_API } from '../config';

// ----------------------------------------------------------------------

const getTokenSilently = async () => {
  const client = await localStorage.getItem('accessToken');
  return client.getTokenSilently();
};

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

axios.interceptors.request.use(
  async (config) => {
    const token = await getTokenSilently();
    config.headers.authorization = `Bearer ${token}`;
    return config;
  },
  (error) => Promise.reject(error)
    );

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>     Promise.reject((error.response && error.response.data) || 'Something went wrong')
    // console.log(error,"Error1")

    // if (error.response?.status === 401) {
    //   alert('Session Expired!!! Please Login Again....');
    // }
  
);

// axios.interceptors.response.use(
//   (response) => {
//     return response.data;
//   },

//   async function checkingss(error) {
//     if (error.response?.status === 401) {
//      alert("Unaunticated")
//     }
//     return Promise.reject(error);
//   }

// );

export default axiosInstance;
