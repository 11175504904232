import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Box } from '@mui/material';
import useSettings from '../hooks/useSettings';
// import useSettings from 'src/hooks/useSettings';
// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const { themeMode } = useSettings();

  const logo = (
    <Box sx={{ width: 200, height: 80, ...sx }}>
      {themeMode === 'light' ? (
        <img alt="login" src="/logo/verified.png" />
      ) : (
        <img alt="login" src="/logo/WhiteLogo.png" />
      )}
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
