// routes
import { Icon } from '@iconify/react';
import { PATH_DASHBOARD } from '../../../routes/paths';

import SvgIconStyle from '../../../components/SvgIconStyle';
// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  location: getIcon('ic_location'),
};


const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      // { title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      // { title: 'e-commerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
      { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // MANAGEMENT : USER
      {
        title: 'Customers',
        path: PATH_DASHBOARD.user.list,
        icon: ICONS.user,
      },
      {
        title: 'Orders',
        path: PATH_DASHBOARD.orders.list,
        icon: <Icon icon="material-symbols:order-approve-outline" />,
      },
      {
        title: 'Other',
        path: PATH_DASHBOARD.eCommerce.root,
        icon: <Icon icon="material-symbols:devices-other-sharp" />,
        children: [
          { title: 'Website Functionality', path: PATH_DASHBOARD.website_functionality.list },
          { title: 'Videos', path: PATH_DASHBOARD.videos.list },
          { title: 'Story', path: PATH_DASHBOARD.story.list },
        ],
      },
      {
        title: 'Professionals',
        path: PATH_DASHBOARD.eCommerce.root,
        icon: <Icon icon="mdi:account-service-outline" />,
        children: [
          { title: 'Professionals', path: PATH_DASHBOARD.professional.list },
          { title: 'Ideas', path: PATH_DASHBOARD.ideas.list},
          { title: 'Projects', path: PATH_DASHBOARD.projects.list},
          { title: 'Category', path: PATH_DASHBOARD.category.list },
          { title: 'Services', path: PATH_DASHBOARD.services.list },
          { title: 'Style', path: PATH_DASHBOARD.style.list },
          { title: 'Locations', path: PATH_DASHBOARD.work_from.list },
        ],
      },
      {
        title: 'Products',
        path: PATH_DASHBOARD.product.list,
        icon: <Icon icon="fluent-mdl2:product-variant" />,
        children: [
          { title: 'Products', path: PATH_DASHBOARD.product.list, icon:<Icon icon="fluent-mdl2:product-variant" /> },
          { title: 'Shops', path: PATH_DASHBOARD.shop.list},
          { title: 'Category', path: PATH_DASHBOARD.product_category.list },
          { title: 'Sub Category', path: PATH_DASHBOARD.product_sub_category.list },
          { title: 'Attributes', path: PATH_DASHBOARD.product_attribute.list },
          { title: 'Attribute Values', path: PATH_DASHBOARD.product_attribute_value.list },
        ],
      },
      {
        title: 'Plans',
        path: PATH_DASHBOARD.plans.list,
        icon: <Icon icon="icon-park-outline:plan" />,
      },

      // MANAGEMENT : BLOG
      // {
      //   title: 'blog',
      //   path: PATH_DASHBOARD.blog.root,
      //   icon: ICONS.blog,
      //   children: [
      //     { title: 'posts', path: PATH_DASHBOARD.blog.posts },
      //     { title: 'post', path: PATH_DASHBOARD.blog.postById },
      //     { title: 'new post', path: PATH_DASHBOARD.blog.newPost },
      //   ],
      // },
    ],
  },

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'mail',
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: (
  //         <Label variant="outlined" color="error">
  //           +32
  //         </Label>
  //       ),
  //     },
  //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
  //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
  //     {
  //       title: 'kanban',
  //       path: PATH_DASHBOARD.kanban,
  //       icon: ICONS.kanban,
  //     },
  //   ],
  // },
];

export default navConfig;
