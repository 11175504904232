import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  allplans: null,
  single_plan: null,
  message: null,
  modal: false,
  isloading: false,
  btnloading:false,
  Errors: null,
};

const slice = createSlice({
  name: 'Plans',
  initialState,
  reducers: {
    get_all_plans(state, action) {
      state.allplans = action.payload;
    },
    get_single_plan(state, action) {
      state.single_plan = action.payload;
    },
    loader(state, action) {
      state.isloading = action.payload;
    },
    btnloader(state, action) {
      state.btnloading = action.payload;
    },
    delete_modal(state) {
      state.modal = !state.modal;
    },
    message(state, action) {
      state.message = action.payload;
    },
    setErrors(state, action) {
      state.Errors = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------


export function openModal() {
  return async () => {
    await dispatch(slice.actions.delete_modal());
  };
}

export function getPlans() {
  return async () => {
    dispatch(slice.actions.setErrors(null));
    dispatch(slice.actions.message(null));
    dispatch(slice.actions.get_single_plan(null));
    try {
      dispatch(slice.actions.loader(true));
      const response = await axios.get('api/admin/plans');
      dispatch(slice.actions.get_all_plans(response.data));
      dispatch(slice.actions.loader(false));
    } catch (error) {
      dispatch(slice.actions.loader(false));
      dispatch(slice.actions.setErrors(error));
    }
  };
}
export function getSinglePlans(id) {
  return async () => {
    try {
      dispatch(slice.actions.loader(true));
      const response = await axios.get(`api/admin/plans/${id}`);
      dispatch(slice.actions.get_single_plan(response.data));
      dispatch(slice.actions.loader(false));
    } catch (error) {
      dispatch(slice.actions.loader(false));
      dispatch(slice.actions.setErrors(error));
    }
  };
}
export function deletePlan(id) {
  return async () => {
    try {
      dispatch(slice.actions.btnloader(true));
      const response = await axios.delete(`api/admin/plans/${id}`);
      dispatch(slice.actions.delete_modal(false));
      dispatch(slice.actions.btnloader(false));
      dispatch(slice.actions.message(response.data.message));
    } catch (error) {
      dispatch(slice.actions.loader(false));
      dispatch(slice.actions.btnloader(false));
      dispatch(slice.actions.setErrors(error));
    }
  };
}
export function AddNewPlan(data) {
  return async () => {
    try {
      dispatch(slice.actions.btnloader(true));
      const response = await axios.post(`api/admin/plans`,data);
      dispatch(slice.actions.message(response.data.message));
      dispatch(slice.actions.setErrors(null));
      dispatch(slice.actions.btnloader(false));
    } catch (error) {
      dispatch(slice.actions.message(null));
      dispatch(slice.actions.btnloader(false));
      dispatch(slice.actions.setErrors(error));
    }
  };
}
export function UpdatePlan(data,id) {
  return async () => {
    try {
      dispatch(slice.actions.btnloader(true));
      const response = await axios.post(`api/admin/plans/${id}`,data);
      dispatch(slice.actions.message(response.data.message));
      dispatch(slice.actions.setErrors(null));
      dispatch(slice.actions.btnloader(false));
    } catch (error) {
      dispatch(slice.actions.message(null));
      dispatch(slice.actions.btnloader(false));
      dispatch(slice.actions.setErrors(error));
    }
  };
}
