import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  details: '',
};

const slice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    get_user_profile(state, action) {
      state.details = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getCart } = slice.actions;

export function getProfile() {
  return async () => {
    try {
      const response = await axios.get('api/admin/profile');
      dispatch(slice.actions.get_user_profile(response.data));
    } catch (error) {
      console.log(error, 'error in redux fetching');
      //   dispatch(slice.actions.hasError(error));
    }
  };
}
