import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
import { configToken } from './configToken';

const initialState = {
  details: '',
  isloading: false,
};

const slice = createSlice({
  name: 'dasboardDetails',
  initialState,
  reducers: {
    dashboard_details(state, action) {
      state.details = action.payload;
    },
    loader(state, action) {
      state.isloading = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getCart } = slice.actions;

export function get_dashboard_details() {
  return async () => {
    try {
      dispatch(slice.actions.loader(true));
      const response = await axios.get('api/admin/dashboard', configToken?.headers);
      dispatch(slice.actions.dashboard_details(response.data));
      dispatch(slice.actions.loader(false));
    } catch (error) {
      dispatch(slice.actions.loader(false));
      console.log(error, 'error in redux fetching');
    }
  };
}
