import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  orders: '',
  isloading: false,
  Errors: '',
};

const slice = createSlice({
  name: 'Orders',
  initialState,
  reducers: {
    get_all_orders(state, action) {
      state.orders = action.payload;
    },
    loader(state, action) {
      state.isloading = action.payload;
    },
    setErrors(state, action) {
      state.Errors = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getorders() {
  return async () => {
    try {
      dispatch(slice.actions.loader(true));
      const response = await axios.get('api/admin/orders');
      dispatch(slice.actions.get_all_orders(response.data));
      dispatch(slice.actions.loader(false));
    } catch (error) {
      dispatch(slice.actions.loader(false));
      dispatch(slice.actions.setErrors(error));
    }
  };
}
