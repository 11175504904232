import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';

import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'reset-password-form/:id', element: <ResetForm /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        { path: 'analytics', element: <GeneralAnalytics /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'booking', element: <GeneralBooking /> },

        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
            { path: 'shop', element: <EcommerceShop /> },
            { path: 'product/:name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'product/new', element: <EcommerceProductCreate /> },
            { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
            { path: 'checkout', element: <EcommerceCheckout /> },
            { path: 'invoice', element: <EcommerceInvoice /> },
          ],
        },
        {
          path: 'professional',
          children: [
            { path: 'list', element: <ProfessionalList /> },
            { path: 'new', element: <ProfessionalCreate /> },
            { path: ':id/edit', element: <ProfessionalCreate /> },
          ],
        },
        {
          path: 'story',
          children: [
            { path: 'list', element: <StoryList /> },
            { path: 'new', element: <StoryCreate /> },
            { path: ':id/edit', element: <StoryCreate /> },
          ],
        },
        {
          path: 'style',
          children: [
            { path: 'list', element: <StyleList /> },
            { path: 'new', element: <StyleCreate /> },
            { path: ':id/edit', element: <StyleCreate /> },
          ],
        },
        {
          path: 'orders',
          children: [
            { path: 'list', element: <OrderList /> },
            { path: 'new', element: <ProfessionalCreate /> },
            { path: ':id/details', element: <ViewOrderDetails /> },
          ],
        },
        {
          path: 'projects',
          children: [
            { path: 'list', element: <ProjectsList /> },
            { path: 'new', element: <ProfessionalCreate /> },
            { path: ':id/details', element: <ViewOrderDetails /> },
          ],
        },
        {
          path: 'website_functionality',
          children: [
            { path: 'list', element: <WebsiteFunctionalityList /> },
            { path: 'new', element: <WebsiteFunctionalityCreate /> },
            { path: ':id/edit', element: <WebsiteFunctionalityCreate /> },
          ],
        },
        {
          path: 'videos',
          children: [
            { path: 'list', element: <VideosList /> },
            { path: 'new', element: <VideosCreate /> },
            { path: ':id/edit', element: <VideosCreate /> },
          ],
        },
        {
          path: 'services',
          children: [
            { path: 'list', element: <ServicesList /> },
            { path: 'new', element: <ServiceCreate /> },
            { path: ':id/edit', element: <ServiceCreate /> },
          ],
        },
        {
          path: 'work_from',
          children: [
            { path: 'list', element: <WorkFromList /> },
            { path: 'new', element: <WorkFromCreate /> },
            { path: ':id/edit', element: <WorkFromCreate /> },
          ],
        },
        {
          path: 'category',
          children: [
            { path: 'list', element: <CategoryList /> },
            { path: 'new', element: <CategoryCreate /> },
            { path: ':id/edit', element: <CategoryCreate /> },
          ],
        },
        {
          path: 'product',
          children: [
            { path: 'list', element: <ProductsList /> },
            { path: 'new', element: <ProductsCreate /> },
            { path: ':id/edit', element: <ProductsCreate /> },
          ],
        },
        {
          path: 'shops',
          children: [
            { path: 'list', element: <ShopsList /> },
            { path: 'new', element: <ShopCreate /> },
            { path: ':id/edit', element: <ShopCreate /> },
          ],
        },
        {
          path: 'professional-Idea',
          children: [
            { path: 'list', element: <IdeasList /> },
            { path: 'new', element: <ShopCreate /> },
            { path: ':id/edit', element: <ShopCreate /> },
            { path: ':id/details', element: <ShopCreate /> },
          ],
        },
        {
          path: 'product_category',
          children: [
            { path: 'list', element: <ProductCategoryList /> },
            { path: 'new', element: <ProductCategoryCreate /> },
            { path: ':id/edit', element: <ProductCategoryCreate /> },
          ],
        },
        {
          path: 'product_sub_category',
          children: [
            { path: 'list', element: <ProductSubCategoryList /> },
            { path: 'new', element: <ProductSubCategoryCreate /> },
            { path: ':id/edit', element: <ProductSubCategoryCreate /> },
          ],
        },
        {
          path: 'product_attribute',
          children: [
            { path: 'list', element: <ProductAttributeList /> },
            { path: 'new', element: <ProductAttributeCreate /> },
            { path: ':id/edit', element: <ProductAttributeCreate /> },
          ],
        },
        {
          path: 'product_attribute_value',
          children: [
            { path: 'list', element: <ProductAttributeValueList /> },
            { path: 'new', element: <ProductAttributeValueCreate /> },
            { path: ':id/edit', element: <ProductAttributeValueCreate /> },
          ],
        },
        {
          path: 'plans',
          children: [
            { path: 'list', element: <PlansList /> },
            { path: 'new', element: <PlansCreate /> },
            { path: ':id/edit', element: <PlansCreate /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':id/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:title', element: <BlogPost /> },
            { path: 'new-post', element: <BlogNewPost /> },
          ],
        },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'kanban', element: <Kanban /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
      children: [
        { element: <HomePage />, index: true },
        { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <Faqs /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
const ResetForm = Loadable(lazy(() => import('../sections/auth/reset-password/ResetForm')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));
const EcommerceInvoice = Loadable(lazy(() => import('../pages/dashboard/EcommerceInvoice')));
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const WebsiteFunctionalityList = Loadable(lazy(() => import('../pages/dashboard/WebsiteFunctionalityList')));
const WebsiteFunctionalityCreate = Loadable(lazy(() => import('../pages/dashboard/WebsiteFunctionalityCreate')));
const ShopsList = Loadable(lazy(() => import('../pages/dashboard/ShopsList')));
const VideosList = Loadable(lazy(() => import('../pages/dashboard/VideosList')));
const VideosCreate = Loadable(lazy(() => import('../pages/dashboard/VideosCreate')));
const StyleCreate = Loadable(lazy(() => import('../pages/dashboard/StyleCreate')));
const ProductsList = Loadable(lazy(() => import('../pages/dashboard/ProductsList')));
const ProductCategoryList = Loadable(lazy(() => import('../pages/dashboard/ProductCategoryList')));
const ProductAttributeList = Loadable(lazy(() => import('../pages/dashboard/ProductAttributeList')));
const ProductAttributeValueList = Loadable(lazy(() => import('../pages/dashboard/ProductAttributeValueList')));
const ProductAttributeCreate = Loadable(lazy(() => import('../pages/dashboard/ProductAttributeCreate')));
const ProductAttributeValueCreate = Loadable(lazy(() => import('../pages/dashboard/ProductAttributeValueCreate')));
const ProductCategoryCreate = Loadable(lazy(() => import('../pages/dashboard/ProductCategoryCreate')));
const ProductSubCategoryList = Loadable(lazy(() => import('../pages/dashboard/ProductSubCategoryList')));
const ProductSubCategoryCreate = Loadable(lazy(() => import('../pages/dashboard/ProductSubCategoryCreate')));
const WorkFromList = Loadable(lazy(() => import('../pages/dashboard/WorkFromList')));
const OrderList = Loadable(lazy(() => import('../pages/dashboard/OrderList')));
const IdeasList = Loadable(lazy(() => import('../pages/dashboard/IdeasList')));
const ProjectsList = Loadable(lazy(() => import('../pages/dashboard/ProjectsList')));
const CategoryList = Loadable(lazy(() => import('../pages/dashboard/CategoryList')));
const ServicesList = Loadable(lazy(() => import('../pages/dashboard/ServicesList')));
const StoryList = Loadable(lazy(() => import('../pages/dashboard/StoryList')));
const StoryCreate = Loadable(lazy(() => import('../pages/dashboard/StoryCreate')));
const ProfessionalList = Loadable(lazy(() => import('../pages/dashboard/ProfessionalList')));
const PlansList = Loadable(lazy(() => import('../pages/dashboard/PlansList')));
const StyleList = Loadable(lazy(() => import('../pages/dashboard/StyleList')));
const PlansCreate = Loadable(lazy(() => import('../pages/dashboard/PlansCreate')));
const ProfessionalCreate = Loadable(lazy(() => import('../pages/dashboard/ProfessionalCreate')));
const ViewOrderDetails = Loadable(lazy(() => import('../pages/dashboard/ViewOrderDetails')));
const CategoryCreate = Loadable(lazy(() => import('../pages/dashboard/CategoryCreate')));
const ShopCreate = Loadable(lazy(() => import('../pages/dashboard/ShopCreate')));
const ProductsCreate = Loadable(lazy(() => import('../pages/dashboard/ProductsCreate')));
const ServiceCreate = Loadable(lazy(() => import('../pages/dashboard/ServiceCreate')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const WorkFromCreate = Loadable(lazy(() => import('../pages/dashboard/WorkFromCreate')));
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));
// Main
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
