// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
  reset_password_form: path(ROOTS_AUTH, '/reset-password-form/:id'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey'),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/:id/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account'),
  },
  professional: {
    root: path(ROOTS_DASHBOARD, '/professional'),
    profile: path(ROOTS_DASHBOARD, '/professional/profile'),
    cards: path(ROOTS_DASHBOARD, '/professional/cards'),
    list: path(ROOTS_DASHBOARD, '/professional/list'),
    newProfessional: path(ROOTS_DASHBOARD, '/professional/new'),
    editById: path(ROOTS_DASHBOARD, `/professional/:id/edit`),
    account: path(ROOTS_DASHBOARD, '/professional/account'),
  },
  services: {
    root: path(ROOTS_DASHBOARD, '/services'),
    list: path(ROOTS_DASHBOARD, '/services/list'),
    newServices: path(ROOTS_DASHBOARD, '/services/new'),
    editById: path(ROOTS_DASHBOARD, `/services/:id/edit`),
  },
  work_from: {
    root: path(ROOTS_DASHBOARD, '/work_from'),
    list: path(ROOTS_DASHBOARD, '/work_from/list'),
    newWorkFrom: path(ROOTS_DASHBOARD, '/work_from/new'),
    editById: path(ROOTS_DASHBOARD, `/work_from/:id/edit`),
  },
  style: {
    root: path(ROOTS_DASHBOARD, '/Style'),
    list: path(ROOTS_DASHBOARD, '/Style/list'),
    newStyle: path(ROOTS_DASHBOARD, '/Style/new'),
    editById: path(ROOTS_DASHBOARD, `/Style/:id/edit`),
  },
  story: {
    root: path(ROOTS_DASHBOARD, '/Story'),
    list: path(ROOTS_DASHBOARD, '/Story/list'),
    newStory: path(ROOTS_DASHBOARD, '/Story/new'),
    editById: path(ROOTS_DASHBOARD, `/Story/:id/edit`),
  },
  category: {
    root: path(ROOTS_DASHBOARD, '/category'),
    list: path(ROOTS_DASHBOARD, '/category/list'),
    newCategory: path(ROOTS_DASHBOARD, '/category/new'),
    editById: path(ROOTS_DASHBOARD, `/category/:id/edit`),
  },
  shop: {
    root: path(ROOTS_DASHBOARD, '/shops'),
    list: path(ROOTS_DASHBOARD, '/shops/list'),
    newShop: path(ROOTS_DASHBOARD, '/shops/new'),
    editById: path(ROOTS_DASHBOARD, `/shops/:id/edit`),
  },
  ideas: {
    root: path(ROOTS_DASHBOARD, '/Professional-Idea'),
    list: path(ROOTS_DASHBOARD, '/Professional-Idea/list'),
    newIdea: path(ROOTS_DASHBOARD, '/Professional-Idea/new'),
    editById: path(ROOTS_DASHBOARD, `/Professional-Idea/:id/edit`),
    detailsById: path(ROOTS_DASHBOARD, `/Professional-Idea/:id/details`),
  },
  projects: {
    root: path(ROOTS_DASHBOARD, '/Projects'),
    list: path(ROOTS_DASHBOARD, '/Projects/list'),
    newProject: path(ROOTS_DASHBOARD, '/Projects/new'),
    editById: path(ROOTS_DASHBOARD, `/Projects/:id/edit`),
    detailsById: path(ROOTS_DASHBOARD, `/Projects/:id/details`),
  },
  website_functionality: {
    root: path(ROOTS_DASHBOARD, '/website_functionality'),
    list: path(ROOTS_DASHBOARD, '/website_functionality/list'),
    new_website_functionality: path(ROOTS_DASHBOARD, '/website_functionality/new'),
    editById: path(ROOTS_DASHBOARD, `/website_functionality/:id/edit`),
    detailsById: path(ROOTS_DASHBOARD, `/website_functionality/:id/details`),
  },
  videos: {
    root: path(ROOTS_DASHBOARD, '/videos'),
    list: path(ROOTS_DASHBOARD, '/videos/list'),
    new_videos: path(ROOTS_DASHBOARD, '/videos/new'),
    editById: path(ROOTS_DASHBOARD, `/videos/:id/edit`),
    detailsById: path(ROOTS_DASHBOARD, `/videos/:id/details`),
  },
  product: {
    root: path(ROOTS_DASHBOARD, '/product'),
    list: path(ROOTS_DASHBOARD, '/product/list'),
    newProduct: path(ROOTS_DASHBOARD, '/product/new'),
    editById: path(ROOTS_DASHBOARD, `/product/:id/edit`),
  },
  product_category: {
    root: path(ROOTS_DASHBOARD, '/product_category'),
    list: path(ROOTS_DASHBOARD, '/product_category/list'),
    newProductCategory: path(ROOTS_DASHBOARD, '/product_category/new'),
    editById: path(ROOTS_DASHBOARD, `/product_category/:id/edit`),
  },
  product_sub_category: {
    root: path(ROOTS_DASHBOARD, '/product_sub_category'),
    list: path(ROOTS_DASHBOARD, '/product_sub_category/list'),
    newProductSubCategory: path(ROOTS_DASHBOARD, '/product_sub_category/new'),
    editById: path(ROOTS_DASHBOARD, `/product_sub_category/:id/edit`),
  },
  product_attribute: {
    root: path(ROOTS_DASHBOARD, '/product_attribute'),
    list: path(ROOTS_DASHBOARD, '/product_attribute/list'),
    new_Product_Attrubite: path(ROOTS_DASHBOARD, '/product_attribute/new'),
    editById: path(ROOTS_DASHBOARD, `/product_attribute/:id/edit`),
  },
  product_attribute_value: {
    root: path(ROOTS_DASHBOARD, '/product_attribute_value'),
    list: path(ROOTS_DASHBOARD, '/product_attribute_value/list'),
    new_Product_Attrubite_Value: path(ROOTS_DASHBOARD, '/product_attribute_value/new'),
    editById: path(ROOTS_DASHBOARD, `/product_attribute_value/:id/edit`),
  },
  orders: {
    root: path(ROOTS_DASHBOARD, '/orders'),
    list: path(ROOTS_DASHBOARD, '/orders/list'),
    new_orders: path(ROOTS_DASHBOARD, '/orders/new'),
    detailsById: path(ROOTS_DASHBOARD, `/orders/:id/details`),
  },
  plans: {
    root: path(ROOTS_DASHBOARD, '/plans'),
    list: path(ROOTS_DASHBOARD, '/plans/list'),
    new_plans: path(ROOTS_DASHBOARD, '/plans/new'),
    detailsById: path(ROOTS_DASHBOARD, `/plans/:id/details`),
  },

  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
